(function () {
    var OK = window.OK || (window.OK = {});

    var logQueue = [],
        errorQueue = [],
        errorKeysQueue = [],
        tracerInitiated = false;

    /**
     * Трейсер автоматически подписывается на ивенты "error" и "unhandledrejection", поэтому дополнительно логировать ошибки в него нет необходимости.
     * Логируем только в Graylog.
     */
    function windowErrorHandler(e) {
        if (tracerInitiated) {
            OK.logger.saveAsClientError(e.error);
        } else {
            OK.Tracer.error(e.error);
        }
    }

    function windowUnhandledRejectionHandler(e) {
        if (tracerInitiated) {
            OK.logger.saveAsClientError(e.reason);
        } else {
            OK.Tracer.error(e.reason);
        }
    }

    /**
     * Заглушка Трейсера до инициализации модуля. Собираем все ошибки и логи в массивы. После того как модуль инициализируется,
     * мы их обработаем, а OK.Tracer примет в качестве значения класс TracerService (см. TracerService.ts)
     */
    OK.Tracer = {
        log: function (data) {
            logQueue.push(data);
        },

        error: function (error, tags) {
            errorQueue.push({
                error: error,
                tags: tags
            });
        },

        // Заглушки, которые будут имплементированы после загрузки модуля
        setErrorKey: function (key, value) {
            errorKeysQueue.push([key, value]);
        },

        tracerAttached: function (tracer) {
            // Применяем все сохранённые данные
            logQueue.forEach(function (log) {
                tracer.log(log);
            });
            errorKeysQueue.forEach(function (params) {
                tracer.setErrorKey(params[0], params[1])
            });
            errorQueue.forEach(function (params) {
                tracer.error(params.error, params.tags);
            });

            // Заполняем OK.Tracer валидными методами
            OK.Tracer = tracer;
            tracerInitiated = true;
        }
    }

    window.addEventListener('error', windowErrorHandler);
    window.addEventListener('unhandledrejection', windowUnhandledRejectionHandler);
})();
